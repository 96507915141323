import React, { useState, useEffect } from "react";
import "./level.css";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setCategoryId } from "./../redux/questionRedux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Level() {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cat = location.pathname.split("/")[2];
  const categoryId = useSelector((state) => state.question.categoryId);
  const [categoryLevel, setCategoryLevel] = useState([]);

  useEffect(() => {
    axios
      .post("https://oplas.cyberx-infosystem.us/api/category-child", {
        categoryId: categoryId,
      })
      .then((res) => {
        setCategoryLevel(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const onClickhandle = (id, route) => {
    // dispatch(setCategoryId(id))
    navigation(`/category/question/${id}/${route}`);
  };
  console.log(cat, "catcatcat");
  return (
    <>
      <Helmet>
        <title> {cat} Form Notes for All Topics - Oplas </title>
        <meta
          name="description"
          content={`${cat} Form Question answer for all topic, comprehensive Civics notes, covering every topic you need for a complete understanding`}
        />
        <link
          rel="canonical"
          href={`https://oplas.co.tz/categories/${cat}/100`}
        />
        <meta name="robots" content="index, follow, imageindex" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`${cat} Form Notes for All Topics - Oplas`}
        />
        <meta
          property="og:description"
          content={`${cat} Form Question answer for all topic, comprehensive Civics notes, covering every topic you need for a complete understanding`}
        />
        <meta property="og:site_name" content="Oplas" />
      </Helmet>
      <div className="categories_main_container">
        <div className="categories_topbar">
          <h2>Level</h2>
          <div className="categories_searchbar">
            <input type="search" placeholder="Find Categories" />
            <SearchIcon id="categories_search_icon" />
          </div>
        </div>

        {/******* categories navbar **********/}
        <div className="categories_navbar">
          <ul>
            <li>
              <a className="active">Name</a>
            </li>
            <li>
              <a></a>
            </li>
          </ul>
        </div>

        {/********* category B  ********/}
        <div className="categ_by_alphabet">
          <div className="category_B">
            <div className="container">
              <div className="row">
                {categoryLevel?.map((item, index) => {
                  return (
                    <div
                      onClick={() => onClickhandle(item.id, item.slug)}
                      key={index}
                      className="col-md-2 p-2 "
                    >
                      <div className="cat-list">
                        <span>{item.name}</span>
                      </div>
                    </div>
                  );
                })}
                {categoryLevel ? null : (
                  <h3 style={{ padding: "20px" }}>No Level</h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Level;

import "./aboutUs.css";
import SearchIcon from "@mui/icons-material/Search";

const Privacy = () => {
    return (
        <div className="about_us_main-container">
            <div className="about_us_topbar">
                <h2>Privacy</h2>
                <div className="about_us_searchbar">
                </div>
            </div>
            <hr />

            <div className="about_us_content">

                <div className="about_us_para">
                    <h3>Privacy Policy</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        This privacy policy has been compiled to better serve those who are concerned with how their 'Personally information' (PI) is being used online. PI, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                    </p>
                </div>

                <div className="about_us_para">
                    <h3>What personal information do we collect from the people that visit our website or app?</h3>
                </div>

                <div className="about_us_para">
                    <p>
                        When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, credit card information or other details to help you with your experience.
                    </p>
                </div>

                <div className="about_us_para">
                    <h3>When do we collect information?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        We collect information from you when you register on our site, place an order, subscribe to a newsletter or enter information on our site.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3>How do we use your information?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        We may use the information we collect from you when you register, make a purchase, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                    </p>

                    <p>
                        •	To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.
                    </p>

                    <p>
                        •	To quickly process your transactions.
                    </p>

                    <p>
                        •	To send periodic emails regarding your order or other products and services.
                    </p>
                </div>

                <div className="about_us_para">
                    <h3>How do we protect visitor information?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        •	We do not use vulnerability scanning and/or scanning to PCI standards.
                    </p>
                    <p>
                        •	We do not use vulnerability scanning and/or scanning to PCI standards.
                    </p>
                    <p>
                        Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
                    </p>
                    <p>
                        We implement a variety of security measures when a user places an order to maintain the safety of your personal information.
                    </p>
                    <p>
                        All transactions are processed through a gateway provider and are not stored or processed on our servers.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3>Do we use 'cookies'?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3>We use cookies to:</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        •	Help remember and process the items in the shopping cart.
                    </p>
                    <p>
                        •	Understand and save user's preferences for future visits.
                    </p>
                    <p>
                        •	Keep track of advertisements.
                    </p>
                    <p>
                        •	Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.
                    </p>
                    <p>
                        If you disable cookies off, some features will be disabled It won't affect the users experience that make your site experience more efficient and some of our services will not function properly. However, you can still place orders.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3>Third Party Disclosure</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
                    </p>
                    <p>
                        However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3>Third party links</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                    </p>
                </div>


                <div className="about_us_para">
                    <h3>We agree to the following</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        •	Users can visit our site anonymously
                    </p>
                    <p>
                        •	Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.
                    </p>
                    <p>
                        •	Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above.
                    </p>

                </div>


                <div className="about_us_para">
                    <h3> Users will be notified of any privacy policy changes:</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        •	Users are able to change their personal information:
                    </p>
                    <p>
                        •	By logging in to their account
                    </p>

                </div>

                <div className="about_us_para">
                    <h3> How does our site handle do not track signals?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3> Does our site allow third party behavioral tracking?</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        It's also important to note that we allow third party behavioral tracking. We will take the following responsive action, should a data breach occur:
                    </p>
                    <p>
                        •	We will notify the users via email
                    </p>
                    <p>
                        •	Within 7 business days, we also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.
                    </p>

                </div>

                <div className="about_us_para">
                    <h3> We collect your email address in order to:</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        •	Send information, respond to inquiries, and/or other requests or questions.
                    </p>
                    <p>
                        •	Process orders and to send information and updates pertaining to orders
                    </p>
                    <p>
                        •	We may also send you additional information related to your product and/or service.
                    </p>
                    <p>
                        •	Market to our mailing list or continue to send emails to our clients after the original transaction has occurred
                    </p>
                    <p>
                        If at any time you would like to unsubscribe from receiving future emails, you can
                        Follow the instructions at the bottom of each email. And we will promptly remove you from ALL correspondence.

                    </p>

                </div>


                <div className="about_us_para">
                    <h3> Contacting Us</h3>
                </div>
                <div className="about_us_para">
                    <p>
                        If there are any questions regarding this privacy policy you may contact us using the information below.
                    </p>
                    <div>
                  
                  <div style={{lineHeight:'1px'}} >
                  <p> Official Headquarter </p>
                    <p> Amani Street, Near Paradise Hotel, </p>
                    <p>Zanzibar-Tanzania </p>
                    <p>+255-717 948 907</p>
                  </div>
                    </div>
                </div>



            </div>
        </div>
    );
};

export default Privacy;

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    useEmail: null,
    logoutMessage: null,
    update:false,
    showModal:false,
    totalPayment:null,
    memberShipRedux:[],
    iframe:null,
    order_tracking_id:null,
    token_pesapal:null
  },
  reducers: {
   
    loginSuccess: (state, action) => {
      state.isFetching = true;
      state.logoutMessage = null;
      state.currentUser = action.payload;
    },
    logout:(state, action)=>{
      state.isFetching = true;
      state.logoutMessage = null;
      state.currentUser = null;
    },
    setUpdate:(state, action)=>{
      state.update = action.payload;
    },
    setModalShows:(state, action)=>{
      state.showModal = action.payload;
    },
    setTotalPayment:(state, action)=>{
      state.totalPayment = action.payload;
    },
    setMemberShipRedux:(state, action)=>{
      state.memberShipRedux = action.payload;
    },
    setIFrame:(state,action)=>{
      state.iframe = action.payload
    },
    setOrderTrackingId:(state,action)=>{
      state.order_tracking_id = action.payload
    },
    setToken:(state,action)=>{
      state.token_pesapal = action.payload
    },
    
  },
});

export const {
  loginSuccess,
  logout,
  setUpdate,
  setModalShows,
  setTotalPayment,
  setMemberShipRedux,
  setIFrame,
  setOrderTrackingId,
  setToken
} = userSlice.actions;
export default userSlice.reducer;

import "./app.css";
import {useEffect} from 'react'
import { Header } from "./components/Header.js";
import { RightSideBar } from "./components/RightSideBar.js";
import { LeftSideBar } from "./components/LeftSideBar.js";
import { Footer } from "./components/Footer.js";
import AllQuestions from "./components/AllQuestions";
import PaymentFailed from "./Pages/PaymentFailed";
import AboutUs from "./Pages/AboutUs.js";
import Home from "./Pages/Home";
import Advertise from "./Pages/Advertise";
import ContactUs from "./Pages/ContactUs";
import Categories from "./Pages/Categories";
import QuestionsByLevel from "./Pages/QuestionsByLevel";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SubjectsLevels from "./Pages/SubjectsLevels";
import Refund from "./Pages/Refund";
import Level from "./Pages/Level";
import Notes from "./Pages/Notes";
import Profile from "./Pages/Profile";
import AllAnswers from "./components/AllAnswers";
import DownloadNote from "./Pages/DownloadNotes";
import NotesLevel from "./Pages/NotesLevel";
import SuccessPage from "./Pages/SuccessPage";
import Membership from "./Pages/Membership";
import Paypal from "./Pages/Paypal";
import Term from "./Pages/Term";
import Privacy from "./Pages/Privacy";
import SubscriptionPage from "./Pages/SubscriptionPage";
import Forget from "./Pages/Forget";
import Reset from "./Pages/Reset";
import Paper from "./Pages/Paper";
import PaperLevel from "./Pages/PaperLevel";
import DownloadPaper from "./Pages/DownloadPaper";
import CategoryQuestion from "./Pages/CategoryQuestion";
import PaymentIFrame from "./Pages/PaymentIFrame";
// import CkEditor from "./components/CkEditor";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Router>
      {/* <CkEditor /> */}
      <Header />
      <div className="front">
        <LeftSideBar />
        <div className="mid_sections">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/failed" element={<PaymentFailed />} />
            <Route path="/forget-password" element={<Forget />} />
            <Route path="/your-plan" element={<SubscriptionPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/category/question/:slug/:slug" element={<CategoryQuestion />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/term" element={<Term />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/questions" element={<AllQuestions />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:slug/:slug" element={<Level />} />
            <Route path="/reset-password/:slug" element={<Reset />} />
            <Route path="/test/response-page" element={<SuccessPage />} />
            
            <Route path="/payment-iframe" element={<PaymentIFrame />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/paper" element={<Paper />} />
            
            <Route path="/payment" element={<Paypal />} />
            <Route path="/notes-level" element={<NotesLevel />} />
            <Route path="/paper-level" element={<PaperLevel />} />
            
            <Route path="/download-notes" element={<DownloadNote />} />
            <Route path="/download-paper" element={<DownloadPaper />} />
            
            <Route path="/questionsbylevel" element={<QuestionsByLevel />} />
            
            <Route
              path="/questionsbylevel/level"
              element={<SubjectsLevels />}
            />
            <Route
              path="/questionsbylevel/level/allquestions"
              element={<AllQuestions />}
            />

            <Route path="/category/question/:slug" element={<AllAnswers />} />
          </Routes>
        </div>
        <RightSideBar />
      </div>
      <Footer />
      {/* <PostQuestionPopup /> */}
    </Router>
  );
}

export default App;

import "./auth.css";
import React, { useState, useEffect } from "react";
import { loginSuccess } from './../redux/userRedux'
import Modal from "react-bootstrap/Modal";
import swal from 'sweetalert'
import { useDispatch, useSelector } from "react-redux";
import { AiFillEye } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import CKEditor from "../Pages/CKEditor";
import axios from "axios";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { authentication } from "../firebase-config";
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'



const Auth = (props) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.user.currentUser)?.token
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [cpassword, setCPassword] = useState('')
  const [showpassword, setShowpassword] = useState(false)
  const [showcpassword, setShowcpassword] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passError, setPassError] = useState(false)
  const [usernameError, setUsernameError] = useState(false)

  const signin = () => {
    // auth.signInWithPopup(provider).catch(alert);
    const provider = new GoogleAuthProvider()
    signInWithPopup(authentication, provider)
      .then((res) => {
        console.log(res,'google resss')
        axios.post(`https://oplas.cyberx-infosystem.us/api/social-login`, {
          "oauth_provider": "google",
          "oauth_uid": res._tokenResponse.localId,
          "email": res._tokenResponse.email,
          "first_name": res._tokenResponse.firstName,
          "last_name": res._tokenResponse.lastName
        })
          .then((res) => {
            console.log(res,'google res apiiiiiiiiiiii')
            if (res.data.status === true) {
              dispatch(loginSuccess(res.data.data))
              localStorage.setItem('token', res.data.data.token)
            } else {
              alert('Login Failed')
            }
          })
          .catch((error) => {
            console.log(error,'google errorrr')
          })
      }).catch((err) => {
        // console.log(err)
      })
  }

  const onChangeEmail = (e) => {
    const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email.test(e.target.value)) {
      setEmailError(true)
    } else if (email.test(e.target.value)) {
      setEmailError(false)
    }
    setEmail(e.target.value)
  }

  setTimeout(() => {
    setEmailError(false)
  }, 10000)

  const onLoginHandle = (e) => {
    e.preventDefault()
    axios.post(`https://oplas.cyberx-infosystem.us/api/login`, {
      "email": email,
      "password": password
    })
      .then((res) => {
        if (res.data.message === 'Email does not Exist') {
          swal('Email does not Exist')
        } else if (res.data.message === 'Incorrect Password') {
          swal('Incorrect Password')
        } else if (res.data.status === true) {
          dispatch(loginSuccess(res.data.data))
          localStorage.setItem('token', res.data.data.token)
          setEmail('')
          setUsername('')
          setPassword('')
          setCPassword('')
          props.onHide()
        }
      })
      .catch((error) => {
        // console.log(error)
      })
  }


  const onSignupHandle = (e) => {
    e.preventDefault()
    if (email === '' || username === '' || password === '') {
      swal('Please Fill the All field')
    } else if (username.length < 8) {
      setUsernameError(true)
      swal('Username should be 8 letter')
    } else if (password.length < 8) {
      setPassError(true)
      swal('Password should be 8 letter')
    }
    else if (cpassword !== password) {
      swal('Confirmed Password does not match')
    } else {
      axios.post(`https://oplas.cyberx-infosystem.us/api/register`, {
        "email": email,
        "name": username,
        "password": password
      })
        .then((res) => {
          if (res.data.message === 'This user already exists') {
            swal('This user already exists')
          } else if (res.data.message === 'Successfully registration') {
            setEmail('')
            setUsername('')
            setPassword('')
            setCPassword('')
            swal({
              title: 'Registration!',
              text: 'Successfully registration'
            }).then(() => {
              setIsLogin(!isLogin)
            })
          }
        }).catch((error) => {
          // console.log(error)
        })
    }
  }
  const componentClicked =()=>{
    
  }
  const responseFacebook = (response) => {
     axios.post(`https://oplas.cyberx-infosystem.us/api/social-login`, {
          "oauth_provider": "facebook",
          "oauth_uid": response.userID,
          "email": response.email?response.email:response.id,
          "first_name": response.name,
          "last_name":''
        })
          .then((res) => {
            if (res.data.status === true) {
              dispatch(loginSuccess(res.data.data))
            localStorage.setItem('token',res.data.data.token)
            } else {
             alert('Login Failed')
            }
          })
          .catch((error) => {
            console.log(error, 'facebook error')
          })
  }
  return (
    <>
      {token ?
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header id="modal_header" closeButton>
            <Modal.Title id="modal_title">
              Ask a Question
            </Modal.Title>
          </Modal.Header>

          <CKEditor />
          <Modal.Body>
          </Modal.Body>
        </Modal>
        :
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header id="modal_header" closeButton>
            {isLogin ? <Modal.Title id="modal_title">
              Sign Up
            </Modal.Title> : <Modal.Title id="modal_title">
              Sign In ,
            </Modal.Title>}
          </Modal.Header>

          <Modal.Body>
            <div classsName="modallogin">
              <form action="">
                <div className="modal_form_fields">
                  <div className="modal_form_field1">
                    <label style={{ color: emailError ? 'red' : 'black' }} htmlFor="user_name_or_email">Email</label>
                    <input type="email" value={email} onChange={onChangeEmail} required />
                    {emailError ? <p style={{ color: 'red' }} >Containe @ and .com</p> : null}
                  </div>



                  {isLogin ? <div className="modal_form_field2">
                    <label style={{ color: usernameError ? 'red' : 'black' }} htmlFor="text" >User Name</label>
                    <input type="text" value={username} style={{ color: usernameError ? 'red' : 'black' }} onChange={(e) => { setUsername(e.target.value); setUsernameError(false) }} required />
                  </div> : null}

                  <div className="modal_form_field2">
                    <label style={{ color: passError ? 'red' : 'black' }} htmlFor="password" >Password*</label>
                    <input type={showpassword ? 'text' : 'password'} style={{ color: passError ? 'red' : 'black' }} value={password} onChange={(e) => { setPassword(e.target.value); setPassError(false) }} required />
                    <AiFillEye className="eye-icon" style={{ color: passError ? 'red' : 'black' }} onClick={() => setShowpassword(!showpassword)} />
                  </div>

                  {isLogin ? <div className="modal_form_field2">
                    <label htmlFor="password" >Confirmed Password</label>
                    <input type={showcpassword ? 'text' : 'password'} value={cpassword} onChange={(e) => setCPassword(e.target.value)} required />
                    <AiFillEye className="eye-icon" onClick={() => setShowcpassword(!showcpassword)} />
                  </div> : null}

                </div>
                <div className="modal_sign_in_btn_and_forget_pw">
                  {isLogin ? <button onClick={onSignupHandle} > Sign Up</button> : <button onClick={onLoginHandle} > Sign In</button>}
                  <a href="/forget-password">forgot password</a>
                </div>

             

              </form>
              <div className="modal_sign_in_btn_and_forget_pw3">
                  <div className="modal_sign_in_btn_and_forget_pw2" >
                    {isLogin ? <p onClick={() => setIsLogin(!isLogin)} className="here-p">Sign-In</p> : <p onClick={() => setIsLogin(!isLogin)} className="here-p">Sign-Up</p>}
                    {isLogin ? <p className="here-p2">Sign-In here</p> : <p className="here-p2">Sign-Up here</p>}
                    {/* <p  className="here-p2">Sign Up here</p> */}
                  </div>

                  <div className="modal_sign_in_btn_and_forget_pw2" >
                    <button className="social-btn" onClick={signin}>
                      G
                    </button>
                    <FacebookLogin
                      appId="659718562396216"
                      fields="name,email,picture"
                      size="small"
                      textButton="F"
                      // icon='home'
                          onClick={componentClicked}
                      callback={responseFacebook}
                       />
                  </div>

                </div>
            </div>
          </Modal.Body>
        </Modal>}
    </>
  );
};

export default Auth;

import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from "react-redux";
import "./paypal.css";
import Modal from "react-bootstrap/Modal";
import { setIFrame, setOrderTrackingId, setToken } from "../redux/userRedux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Paypal() {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const [ipnId, setIpnId] = useState('')
  const [choosePaymentMode, setChoosePaymentMode] = useState('')
  const [accountNo, setAccountNo] = useState('')
  const [tokenRes, setTokenRes] = useState('')
  const payment = useSelector((state) => state.user.totalPayment)
  const user = useSelector((state) => state.user.currentUser)
  const membershipId = useSelector((state) => state.question.categoryId)
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {

    axios.post(`https://pay.pesapal.com/v3/api/Auth/RequestToken`, {
        "consumer_key": "4uVTKAGI8JgblDnyeNAKDBRBdB0KMf/1",
          "consumer_secret": "yJ7+X1+KSX/kluu2nrPpaohpAJ8="
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      setTokenRes(res.data.token)
      dispatch(setToken(res.data.token))

      axios.post(`https://pay.pesapal.com/v3/api/URLSetup/RegisterIPN`, {
        "url": "https://oplas.co.tz/test/ipn",
        "ipn_notification_type": "GET"
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${res.data.token}`
        }
      }).then((res) => {
        setIpnId(res.data.ipn_id)
      }).catch((err) => {
        console.log(err)
      })
    }).catch((err) => {
      console.log(err)
    })


  }, []);

  const finalPayment = () => {

    const id = Math.floor( Math.random() * 578675756568700)

    axios.post(`https://pay.pesapal.com/v3/api/Transactions/SubmitOrderRequest`,
      accountNo === ''
        ?
        {
          "id": id,
          "currency": "TZS",
          "amount": payment.price,
          "description": "Payment description goes here",
          "type": "ORDER",
          "callback_url": "https://oplas.co.tz/test/response-page",
          "notification_id": ipnId,
          "billing_address": {
            "email_address": user.user.email,
            "phone_number": user.user.phone,
            "country_code": "TZ",
            "first_name": user.user.name,
            "middle_name": "",
            "last_name": user.user.name,
            "line_1": "Pesapal Limited",
            "line_2": "",
            "city": "",
            "state": "",
            "postal_code": "",
            "zip_code": "",
          }
        }
        :
        {
          "id": id,
          "account_number": accountNo,
          "currency": "TZS",
          "amount": payment.price,
          "description": "Payment description goes here",
          "type": "ORDER",
          "callback_url": "https://oplas.co.tz/test/response-page",
          "notification_id": ipnId,
          "billing_address": {
            "email_address": user.user.email,
            "phone_number": user.user.phone,
            "country_code": "TZ",
            "first_name": user.user.name,
            "middle_name": "",
            "last_name": user.user.name,
            "line_1": "Pesapal Limited",
            "line_2": "",
            "city": "",
            "state": "",
            "postal_code": "",
            "zip_code": "",
          }
        }
      ,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenRes}`
        }
      }).then((response) => {
        /////////////// create

        axios.get(`https://pay.pesapal.com/v3/api/Transactions/GetTransactionStatus?orderTrackingId=${response.data.order_tracking_id}`,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${tokenRes}`
            }
          })
          .then((res) => {

            axios.post('https://oplas.cyberx-infosystem.us/api/orders/create', {
              "membership_id": membershipId,
              "payment_id": response.data.order_tracking_id,
              "payment_method": "Online",
              "amount": payment.price,
              "payment_status": "true",
              "merchant_reference": res.data.merchant_reference,
              "is_active": res.data.status_code,
              "OrderTrackingId": response.data.order_tracking_id,
              "confirmation_code": res.data.confirmation_code,
              "payment_status_description": res.data.payment_status_description,
              "expire_date": '',
              "user_id": '',
              "currency": "TZS"
            }, {
              headers: {
                "Authorization": user?.token
              }
            }).then((res) => {
              if (res.data.status === true) {
                navigation('/payment-iframe')
              } else {
                navigation('/failed')
                alert('Something went wrong')
              }
            }).catch((err) => {
              console.log(err, 'errrrrr')
            })

          }).catch((err) => {
            console.log(err)
          })
        dispatch(setOrderTrackingId(response.data.order_tracking_id))
        dispatch(setIFrame(response.data.redirect_url))

      }).catch((err) => {
        console.log(err)
      })
  }
  return (
  
    <div>
      <div className="wrapper">
        <div className="product-img">
     
        </div>
        <div className="product-info">
          <div className="product-price-btn">
            <div className="main-paypal">
              <span className="title">
                {payment.title.toLocaleUpperCase()}
              </span>
              <span>Expire in {payment.expire_type.toLocaleUpperCase()}</span>
              <span className="price">
                {payment.price} TZS
              </span>
            </div>
            <button className="okay-payment-button"  type="submit" onClick={handleShow}>
              Buy now
            </button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Choose Your Payment option</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <select onChange={(e) => { setChoosePaymentMode(e.target.value); setAccountNo('') }} value={choosePaymentMode} className="form-control my-3" name="cars" id="cars">
                  <option selected value="Choose Mode">Choose Mode</option>
                  <option value="One Time Payment">One Time Payment</option>
                  <option value="Recurring Payment">Recurring Payment</option>
                </select>
                {
                  choosePaymentMode === 'Recurring Payment'
                    ?
                      <input
                      id="cc-number"
                      type="tel"
                      className="input-lg form-control cc-number my-4"
                      autoComplete="cc-number"
                      placeholder="Enter Account Number"
                      // name="cardNumber"
                      value={accountNo}
                      onChange={(e)=>{
                        setAccountNo(e.target.value)
                      }}
                      maxLength='19'
                      required
                    />
                    :
                    ''
                }
                 <button className="btn btn-primary okay-payment-button" onClick={finalPayment}>
                  Okay
                </button>
              </Modal.Body>
               
            </Modal>


          </div>
        </div>
      </div>


    </div>
    //  {/* </PayPalScriptProvider> */}
  );
}
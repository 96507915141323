import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setMemberShipRedux } from "../redux/userRedux";


const SubscriptionPage = () => {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user.currentUser)?.token
    const [membership, setMembership] = useState()
    useEffect(() => {
        axios.post('https://oplas.cyberx-infosystem.us/api/orders', {
        }, {
            headers: {
                "Authorization": token
            }
        }).then((res) => {
            setMembership(res.data.data)
            dispatch(setMemberShipRedux(res.data.data))
        }).catch((err) => console.log(err, 'errrjhfuyfi'))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    var date = new Date().toISOString()

    return (
        <>
            <div className="container">
                <div className="row">
                    {membership?.map((item, index) => {
                        return (
                            <div key={index} className="col-md-4 p-2">
                                <div className="cat-list" >
                                    <span>{item.payment_method.toLocaleUpperCase()} Payment Mode</span>
                                    <span className="price-tag">{item.amount} TZS </span>
                                    <span > Expired within <span className="price-tag2" > {item.expire_number}  {item.expire_type}</span> </span>
                                    <span > Expired Date <span className="price-tag2" > {item.expire_date} </span> </span>
                                    <span > Payment Id : <span className="price-tag2" > {item.payment_id} </span> </span>
                                    <span > Status : <span className="status" style={{backgroundColor:item.expire_date > date ? 'green' : 'red'}} > {item.expire_date > date ? 'Active' : 'Expire'}</span> </span>
                                </div>
                            </div>
                        )
                    })}
                    {membership ? null : <h3 style={{ padding: '20px' }}>You do not have any subscriptions</h3>}
                </div>
            </div>
        </>
    )
}

export default SubscriptionPage
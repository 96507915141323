import React,{useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './forget.css'

const Forget =()=>{
    const [email, setEmail] = useState('')
    const navigation = useNavigate()
    const onSendLink=()=>{
       if(email===''){
        alert('Enter the email')
       }else {
        axios.post(`https://oplas.cyberx-infosystem.us/api/forgot-password`,{
            "email":email
        }).then((res)=>{
            if(res.data.status===true){
                alert('Link has been send to your email')
                setEmail('')
                navigation('/')
            } else{
                alert('Email is not Registered')
            }
        }).catch((err)=>console.log(err))
       }
    }
    return(
        <div className="main_forget" >
            <h2>Forget Password </h2>
            <div className="input_tag">
            <label htmlFor="password" >Enter Registered Email</label>
                <input type='text' placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <button onClick={onSendLink} >Send</button>
            </div>
        </div>
    )
}

export default Forget
import "./contactUs.css";
import {useState, useEffect} from 'react'
import SearchIcon from "@mui/icons-material/Search";
import { MdLocationOn } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import swal from "sweetalert";
import axios from "axios";

function ContactUs() {
  
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject,setSubject]= useState('')
  const [message, setMessage] = useState('')
  
  const HandleSendQuery =(e)=>{
    e.preventDefault()
   if(firstName===''||email===''||message===''|| phone===''|| subject===''){
    swal('Please Enter the field')
   }else {
    axios.post('https://oplas.cyberx-infosystem.us/api/inquiry',{
      "name":firstName,
      "email":email,
      "message":message,
      "phone":phone
    }).then((res)=>{
      swal(res.data.message)
      setEmail('')
      setFirstName('') 
      setMessage('')
      setSubject('')
      setPhone('')
    }).catch((err)=>{
    })
   }
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <div className="contact_main_container w-100">
        <div className="contact_topbar">
          <h2>Contact</h2>
          <div className="contact_searchbar">
          </div>
        </div>
        <hr />

        <div className="contact_cards">
          <div className="contact_cards_row1">
            <div className="contact_card">
              <h2>HEAD OFFICE – ZANZIBAR.</h2>
              <div className="location">
                <MdLocationOn color="#e05d30" />
                <span>
                  P.o.box 1457 Zanzibar, Tanzania. Mikunguni Street, Urban West.
                </span>
              </div>
              <div className="mobile">
                <IoMdCall color="#e05d30" />
                <span>+255717948907</span>
              </div>
              <div className="email">
                <MdEmail color="#e05d30" />
                <span>support@oplas.co.tz</span>
              </div>
            </div>

            <div className="contact_card">
              <h2>HEAD OFFICE – ZANZIBAR.</h2>
              <div className="location">
                <MdLocationOn color="#e05d30" />
                <span>
                  P.o.box 1457 Zanzibar, Tanzania. Mikunguni Street, Urban West.
                </span>
              </div>
              <div className="mobile">
                <IoMdCall color="#e05d30" />
                <span>+255717948907</span>
              </div>
              <div className="email">
                <MdEmail color="#e05d30" />
                <span>support@oplas.co.tz</span>
              </div>
            </div>
          </div>

          <div className="contact_cards_row2">
            <div className="contact_card">
              <h2>HEAD OFFICE – ZANZIBAR.</h2>
              <div className="location">
                <MdLocationOn color="#e05d30" />
                <span>
                  P.o.box 1457 Zanzibar, Tanzania. Mikunguni Street, Urban West.
                </span>
              </div>
              <div className="mobile">
                <IoMdCall color="#e05d30" />
                <span>+255717948907</span>
              </div>
              <div className="email">
                <MdEmail color="#e05d30" />
                <span>support@oplas.co.tz</span>
              </div>
            </div>

            <div className="contact_card">
              <h2>HEAD OFFICE – ZANZIBAR.</h2>
              <div className="location">
                <MdLocationOn color="#e05d30" />
                <span>
                  P.o.box 1457 Zanzibar, Tanzania. Mikunguni Street, Urban West.
                </span>
              </div>
              <div className="mobile">
                <IoMdCall color="#e05d30" />
                <span>+255717948907</span>
              </div>
              <div className="email">
                <MdEmail color="#e05d30" />
                <span>support@oplas.co.tz</span>
              </div>
            </div>
          </div>
        </div>

        {/******* form  *********/}

        <div className="contact_form">
          <div className="contact_form_heading">
            <h5>For any query , Please contact us!</h5>

            <form onSubmit={HandleSendQuery}>
              <div className="contact_form_fields">
                <div className="contact_form_col1_fields">
                  <label htmlFor="fname">First Name</label>
                  <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
                  <label htmlFor="phone"  >Phone</label>
                  <input type="number" value={phone} onChange={(e)=>setPhone(e.target.value)} />
                </div>

                <div className="contact_form_col2_fields">
                  <label htmlFor="lname" >Email</label>
                  <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                  <label htmlFor="text">Subjects</label>
                  <input type="text" value={subject} onChange={(e)=>setSubject(e.target.value)}  />
                </div>
              </div>
              <div className="contact_messege_and_submit">
                <label htmlFor="messege">Your messege</label>
                <textarea style={{padding:'5px'}} placeholder="Type..." value={message} onChange={(e)=>setMessage(e.target.value)}  />
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;

import "./questionsByLevel.css";
import {useState, useEffect} from 'react'
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import axios from "axios";


function QuestionsByLevel() {
  const navigate = useNavigate();

  function handleSubjects(e) {
    navigate("level", { state: e.target.innerText });
  }
  
  const [category , setCategory] = useState(null)
  
  useEffect(()=>{
    axios
    .post("https://oplas.cyberx-infosystem.us/api/category",{})
    .then((response) => {
      setCategory(response.data.data);
    }).catch((err)=>console.log(err))
  },[])
  
  return (
    <div className="ques_by_level_main_container">
      <div className="ques_by_level_topbar">
        <h2>Questions By Level</h2>
        <div className="ques_by_level_searchbar">
          <input type="search" placeholder="search at blog" />
          <SearchIcon id="ques_by_level_search_icon" />
        </div>
      </div>

      <div className="ques_by_level_buttons">
      <div className="button_collections">
       {category?.map((item, index)=>{
        return  <button  key={index}  onClick={handleSubjects}>{item.name.toUpperCase()}</button>
        
          })}
            </div>
      </div>
    </div>
  );
}

export default QuestionsByLevel;

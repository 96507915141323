import "./allQuestions.css";
import React, { useState, useEffect } from "react";
import { MidSectionCards } from "./MidSectionCards";
import { useLocation } from "react-router-dom";
import {useSelector} from 'react-redux'
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import axios from "axios";

const AllQuestions = (props) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [update, setUpdate] = useState(false)
  const [questionData, setQuestionData] = useState([]);
  const [length, setlength] = useState()
  const [selectTab, setSelectTab] = useState('latest')
  const [category, setCategory] = useState(null)
  const [dataFound, setDataFound] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const user = useSelector((state)=>state.user.currentUser)
  const updates = useSelector((state)=>state.user.update)
  const searchQues = useSelector((state)=>state.question.searchQuestion)

  useEffect(() => {
    axios.post('https://oplas.cyberx-infosystem.us/api/questions-search', {
      "keywords":searchQues,
      "categoryId": 0,
      "offset": 0,
      "type": "latest",
      "search_by":'question'
    })
      .then((response) => {
        setlength(response.data.data.totalQuestion)
        setQuestionData(response.data.data);
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [searchQues])
  
  
  useEffect(() => {
    axios
      .post(
        "https://oplas.cyberx-infosystem.us/api/questions",
        {
          categoryId: selectedCategory!==null?selectedCategory:"0",
          offset: page2,
          type: "latest",
          "user_id":user?user.user?.id:"0"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if(response.data.message==='Not found questions'){
          setDataFound(true)
        }else {
          setlength(response.data.data.totalQuestion)
          setQuestionData(response.data.data);
          setDataFound(false)
        }
        
      });

    axios
      .post("https://oplas.cyberx-infosystem.us/api/category", {})
      .then((response) => {
        setCategory(response.data.data);
      }).catch((err) => console.log(err))

  }, [update,selectedCategory,updates]);


  let lastNumber = Math.ceil(length / 10)
  const handleChange = (value) => {
    setPage(value);
    setPage2(page2 + 10)
    setUpdate(!update)
  };

  const onClickLatest = () => {
    setSelectTab('latest')
  }

  const onClickVotes = () => {
    setSelectTab('votes')
    axios
      .post(
        "https://oplas.cyberx-infosystem.us/api/questions",
        {
          categoryId: selectedCategory!==null?selectedCategory:"0",
          offset: page2,
          type: "votes",
          "user_id":user?user.user?.id:"0"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setlength(response.data.data.totalQuestion)
        setQuestionData(response.data.data);
      });
  }

  const onClickunanswered = () => {
    setSelectTab('unanswered')
    axios
      .post(
        "https://oplas.cyberx-infosystem.us/api/questions",
        {
          categoryId: selectedCategory!==null?selectedCategory:"0",
          offset: page2,
          type: "unanswered",
          "user_id":user?user.user?.id:"0"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setlength(response.data.data.totalQuestion)
        setQuestionData(response.data.data);
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container-fluid containers ">
      <div className="all_que_topbar">
        <h5>
          All Questions
          {location.state
            ? `${location.state.subjectName},${location.state.level}`
            : ""}
        </h5>
        <div className="dropdowns">
          <form  id="all_que_form">
           <div className="filter_cat">
           <label id="label1" htmlFor="cars">
              Filter by
            </label>
            <select onChange={(e) => setSelectedCategory(e.target.value)} name="cars" id="dropdown1">
              <option value="Select">Select Categories</option>
              {category?.map((item, index) => {
                return <option key={index} value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</option>
              })}
            </select>
            
           </div>
          </form>
        </div>
      </div>
      {/************** mid section navbar *************/}
      <div className="mid_container_nav">
        <div id="mid_nav">
          <div className="left_nav">
            <ul>
              <li onClick={onClickLatest} >
                <a className={selectTab === 'latest' ? 'active' : ''} >Latest</a>
              </li>
              <li onClick={onClickVotes} >
                <a className={selectTab === 'votes' ? 'active' : ''}  >Votes</a>
              </li>
              <li onClick={onClickunanswered}>
                <a className={selectTab === 'unanswered' ? 'active' : ''}  >Unasnswered</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {dataFound===true?
      <h2 className="p-4" >No Data Found</h2>
    :
    <MidSectionCards questionData={questionData} selectedCategory={selectedCategory} />
    }
     
      <div className="card_page_number m-4">
        
        <Stack spacing={2}>
           <Pagination count={lastNumber} onChange={handleChange} color="primary" />
        </Stack>
      </div>
    </div>
  );
};

export default AllQuestions;

import React from "react";
import './allservices.css'
import {useEffect} from 'react'
import {FaUserGraduate} from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Auth from "../auth/Auth";
import { useState } from "react";

const AllServices =()=>{
    const navigation = useNavigate()
    const [checkLogin, setCheckLogin] = useState(false)
    const token = useSelector((state)=>state.user.currentUser)?.token
    
    const onClickNotes =()=>{
        if(!token){
            setCheckLogin(true)
        } else{
            navigation('/notes')
        }
    }
    
    const onClickPaper =()=>{
        if(!token){
            setCheckLogin(true)
        } else{
            navigation('/paper')
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        {checkLogin?<Auth show={checkLogin} onHide={() => setCheckLogin(false)} />:null}
        
        <div className="service_page" >
          <div className="main_services" >
            <div className="circle" onClick={onClickNotes} >
               <FaUserGraduate className="icons" />
               <h5>Notes</h5>
            </div>
            
            <div className="circle2" onClick={onClickPaper} >
               <FaUserGraduate className="icons2" />
               <h5>Previous Paper</h5>
            </div>
            
          </div>
        </div>
        </>
    )
}

export default AllServices
import "./aboutUs.css";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Refund = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="about_us_main-container">
            <div className="about_us_topbar">
                <h2>Refund & Cancellation</h2>
                <div className="about_us_searchbar">
                </div>
            </div>
            <hr />

            <div className="about_us_content">

                <div className="about_us_para">
                    <h3 className="text-center p-3" >Refund & Cancellation Policy</h3>
                </div>
                <div className="about_us_para">
                    <p>
                    At Oplas partnership, we have full confidence in our varied range of services, products and e-education solutions. We leave no stone unturned in providing our esteemed clients fast, reliable and exceptional service guarantee every time in time we do business with them. All the services available at oplas’s website/Apps are carried out for the clients after extensive project analysis using a complete scope document. It ensures full understanding of the work and almost no possibilities of any project cancellation, reversal or dispute. However, any refund and service cancellation is taken place abiding a set of conditions.
                    </p>
                </div>

                <div className="about_us_para">
                    <h4>Cancellation Policy</h4>
                </div>

                <div className="about_us_para">
                    <p>
                    •	A subscriber can place the cancellation order for a particular service within 7-10 days of service agreement via prescribed cancellation form available on our website.
                    </p>
                    <p>
                    •	No cancellation is possible for the services offered with promotional discounts on special occasions viz. These are limited edition offers and hence any range of refund would not be possible
                    </p>

                    <p>
                    •	Fee once paid will not be refunded in any case even if the cancellation is posted within 12 hours
                    </p>
                    <p>
                    •	A service stands cancel if next payment is not received within 7 working days
                    </p>
                    
                </div>

                <div className="about_us_para">
                    <h4>Refund Policy</h4>
                </div>
                <div className="about_us_para">
                    <p>
                    •	Every service offered by us takes different course of action to get accomplished from start to end thus refund policy differs from one service to other.
                    </p>
                    <p>
                    •	There is always a certain set of resources that are involved in achieving every milestone of a campaign. Thus, a refund would not be entertained for the work already completed.
                    </p>
                    <p>
                    •	No refund is possible for the fee paid in the form of administrative fees or installation charges.
                    </p>
                    <p>
                    •	Taxes paid on services levied by regulatory bodies would not be refunded with fee refund.
                    </p>
                    <p>
                    •	No refund will be entertained in case service gets canceled due to any violation of terms stated in our Terms & Condition section.
                    </p>
                    <p>
                    •	There is no refund policy for the services placed under same day delivery.
                    </p>
                    <p>
                    •	Oplas is not liable to pay refund if delay or service disruption happens due to third party involvement.
                    </p>

                </div>


               

        
           
                
            </div>
        </div>
    );
};

export default Refund;

import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: "question",
  initialState: {
    categoryId: null,
    searchQuestion:''
  },
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setSearchQuestion:(state,action)=>{
      state.searchQuestion = action.payload
    }
 

  },
});

export const {
    setCategoryId,setSearchQuestion
} = questionSlice.actions;
export default questionSlice.reducer;

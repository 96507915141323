import "./../components/allQuestions.css";
import React, { useState, useEffect } from "react";
import { MidSectionCards } from "./../components/MidSectionCards";
import { useLocation } from "react-router-dom";
import {useSelector} from 'react-redux'
import Pagination from '@mui/material/Pagination';
import axios from "axios";

const CategoryQuestion = (props) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [update, setUpdate] = useState(false)
  const [questionData, setQuestionData] = useState([]);
  const [length, setlength] = useState()
  const [category, setCategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const user = useSelector((state)=>state.user.currentUser)
  const updates = useSelector((state)=>state.user.update)
  const searchQues = useSelector((state)=>state.question.searchQuestion)
  const categoryId= useSelector((state)=>state.question.categoryId)
  useEffect(() => {
    axios.post('https://oplas.cyberx-infosystem.us/api/questions-search', {
      "keywords":searchQues,
      "categoryId": 0,
      "offset": 0,
      "type": "latest",
      "search_by":'question'
    })
      .then((response) => {
        setlength(response.data.data.totalQuestion)
        setQuestionData(response.data.data);
      })
      .catch((err) => console.log(err))
  }, [searchQues])
  
  
  useEffect(() => {
    axios
      .post(
        "https://oplas.cyberx-infosystem.us/api/questions",
        {
          categoryId:categoryId,
          offset: '0',
          type: "latest",
          "user_id":user?user.user?.id:"0"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setlength(response.data.data.totalQuestion)
        setQuestionData(response.data.data);
      });

    axios
      .post("https://oplas.cyberx-infosystem.us/api/category", {})
      .then((response) => {
        setCategory(response.data.data);
      }).catch((err) => console.log(err))

  }, [update,selectedCategory,updates]);


  let lastNumber = Math.ceil(length / 10)
  const handleChange = (value) => {
    setPage(value);
    setPage2(page2 + 10)
    setUpdate(!update)
  };



  

  return (
    <div className="container-fluid containers ">
      <div className="all_que_topbar">
        <h5>
           Questions By Category
          {location.state
            ? `${location.state.subjectName},${location.state.level}`
            : ""}
        </h5>
        <div className="dropdowns">
          <form  id="all_que_form">
           <div className="filter_cat">
           <label id="label1" for="cars">
              Filter by
            </label>
            <select onChange={(e) => setSelectedCategory(e.target.value)} name="cars" id="dropdown1">
              <option value="Select">Select Categories</option>
              {category?.map((item, index) => {
                return <option key={index} value={item.id}>{item.name}</option>
              })}
            </select>
            
           </div>
          </form>
        </div>
      </div>
      <MidSectionCards questionData={questionData} selectedCategory={selectedCategory} />
      <div className="card_page_number m-4">
        {/* <Pagination>{items}</Pagination> */}
        <Pagination count={lastNumber} page={page} onChange={handleChange} color="primary" />
      </div>
    </div>
  );
};

export default CategoryQuestion;

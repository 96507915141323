import React, { useState, useEffect } from "react";
import axios from "axios";
import Editor from "./Editor";
import { useSelector, useDispatch } from "react-redux";
import swal from 'sweetalert'
import { setUpdate } from './../redux/userRedux'
import './editor.css'

export default function CKEditor(props) {
    const dispatch = useDispatch()
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [data, setData] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedtag, setSelectedtag] = useState(null)
    const [category, setCategory] = useState(null)
    const [myQuestion, setMyQuestion] = useState('')
    const [tag, setTag] = useState()
    const token = useSelector((state) => state.user.currentUser)?.token
    const update = useSelector((state) => state.user.update)

    useEffect(() => {
        axios
            .post("https://oplas.cyberx-infosystem.us/api/category", {})
            .then((response) => {
                setCategory(response.data.data);
            }).catch((err) => console.log(err))

        axios.post('https://oplas.cyberx-infosystem.us/api/tags', {})
            .then((res) => {
                setTag(res.data.data)
            })
            .catch((err) => {
            })
    }, [])
    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    const onHandlePostQuestion = () => {

        axios.post('https://oplas.cyberx-infosystem.us/api/question-ask', {
            "title": myQuestion,
            "description": data,
            "categoryId": selectedCategory,
            "tags": [selectedtag]
        }, {
            headers: {
                "Authorization": token
            }
        }).then((res) => {
            swal('Post answer Successfully')
            dispatch(setUpdate(!update))
        }).catch((err) => {
            swal('Something went wrong')
        })
    }

    return (
        <div className="App" style={{ padding: '20px', margin: '10px' }} >
            <input type='text' value={myQuestion} onChange={(e) => setMyQuestion(e.target.value)} className="form-control" placeholder="Your Question" />
            <select onChange={(e) => setSelectedCategory(e.target.value)} name="cars" id="dropdown1">
                <option value="Select">Select Categories</option>
                {category?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>
                })}

            </select>
            <Editor
                name="description"
                onChange={(data) => {
                    setData(data);
                }}
                editorLoaded={editorLoaded}
            />
            <select onChange={(e) => setSelectedtag(e.target.value)} name="cars" id="dropdown1">
                <option value="Select">Tag Max 5tags</option>
                {tag?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>
                })}

            </select>

            <button onClick={onHandlePostQuestion} className="submit-question-btn">SUBMIT QUESTION</button>
            <div className="policy" >By posting your question, you agree to the <span style={{color:'green', cursor:'pointer'}} >privacy policy</span> and  
            <span style={{color:'green', cursor:'pointer'}} >terms of service.</span> </div>
        </div>
    );
}

import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyA7jr6FR0BAlv77puejV0j6GCaAm2WLH2o",
    authDomain: "oplas-ae424.firebaseapp.com",
    projectId: "oplas-ae424",
    storageBucket: "oplas-ae424.appspot.com",
    messagingSenderId: "1013228762328",
    appId: "1:1013228762328:web:30a20bfa5c7a38f2c713ad",
    measurementId: "G-427MXT6SG2"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app)
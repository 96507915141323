import React, { useState } from "react";
import "./leftSideBar.css";
import { HiPlusSm } from "react-icons/hi";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BsTrophyFill } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { MdSubscriptions } from "react-icons/md";
import { BsQuestionLg } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { ImGoogle2 } from "react-icons/im";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Auth from "../auth/Auth";
import PostQuestionPopup from "./PostQuestionPopup";

export const LeftSideBar = (props) => {
  const navigation =useNavigate()
  const [modalShow, setModalShow] = useState(false);
  const token = useSelector((state)=>state.user.currentUser)?.token
  const [isLogin, setIsLogin] = useState(false); // if isLogin true, PostQuestion Modal will be open otherwise Login Modal will open

  const handleClick =()=>{
      setModalShow(true)
  }
  
  return (
    <div className="left_side_bar">
      <button id="ask_question" onClick={() => setModalShow(true)}>
        <HiPlusSm size="20" />
        ASK A QUESTION
      </button>

      {isLogin ? (
        <PostQuestionPopup
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : (
        <Auth isLogin show={modalShow} onHide={() => setModalShow(false)} />
      )}

      <div className="left_side_hr"></div>
      {/**************   left side bar list ***********/}
      <div className="left_side_bar_list">
        <ul>
          <li>
            <AiFillQuestionCircle color="#56af3e" />

            <a href="#">
              <Link to="questions" style={{ color: "#56af3e" }}>
                Questions
              </Link>
            </a>
          </li>
          <li>
            <BsTrophyFill color="#56af3e" />

            <a href="#">Badges</a>
          </li>
          {/* <Auth isLogin show={modalShow} onHide={() => setModalShow(false)} /> */}
          <li>
            <MdSubscriptions color="#56af3e" />
            <a href="#">
            <Link style={{ color: "#56af3e" }} to="membership">
               Plans
              </Link>
            </a>
          </li>
          <li>
            <BiCategory color="#56af3e" />
            <a href="#">
              <Link to="categories" style={{ color: "#56af3e" }}>
                Categories{" "}
              </Link>
            </a>
          </li>
          {
            token ? 
            <li>
            <MdSubscriptions color="#56af3e" />
            <a href="#">
            <Link style={{ color: "#56af3e" }} to='your-plan'>
               Subscription
              </Link>
            </a>
          </li>
          :
          <li>
          <MdSubscriptions color="#56af3e" />
          <a href="#">
          <Link style={{ color: "#56af3e" }} onClick={handleClick}>
             Subscription
            </Link>
          </a>
        </li>
          }
          <li>
            <BsQuestionLg color="#56af3e" />
            <a href="#">
              <Link style={{ color: "#56af3e" }} to="questionsbylevel">
                Question By Level
              </Link>
            </a>
          </li>
        </ul>
      </div>

      {/************ social icons *************/}
      <div className="left_sidebar_social_icons">
        <ul>
          <a href='https://www.facebook.com/oplastz' target="_blank" >
            <FaFacebookSquare size="30" style={{ opacity: "0.5" }} />
          </a>
          <a href='https://twitter.com/oplas_tz' target="_blank" >
            <FaTwitterSquare size="30" style={{ opacity: "0.5" }} />
          </a >
        </ul>
      </div>
      <div className="left_sidebar_footer">
        <small>©2022 Oplas Answer Inc</small>
        <div>
        <small className="term" onClick={()=>navigation('/term')} >Terms | </small>
        <small className="term" onClick={()=>navigation('/privacy')} >Privacy</small>
        </div>
      </div>
    </div>
  );
};

// import React, { useState, useEffect } from "react";
// import "./membership.css";
// import SearchIcon from "@mui/icons-material/Search";
// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { setCategoryId } from './../redux/questionRedux'
// import { setTotalPayment } from "../redux/userRedux";
// import Auth from "../auth/Auth";
// import axios from 'axios'


// function Membership() {
//   const navigation = useNavigate()
//   const dispatch = useDispatch()
//   const [modalShow, setModalShow] = useState(false);
//   const token = useSelector((state) => state.user.currentUser)?.token
//   const [category, setCategory] = useState(null)
//   const [searchCategory, setSearchCategory] = useState('')
//   const [membership, setMembership] = useState([])

//   useEffect(() => {
//     axios
//       .post("https://oplas.cyberx-infosystem.us/api/membership-plan", {}, {
//         headers: {
//           "Authorization": token
//         }
//       })
//       .then((response) => {
//         setMembership(response.data.data);
//       }).catch((err) => console.log(err))
//   }, [])

//   const onCategoryHandle = (id, price) => {
//     dispatch(setCategoryId(id))
//     dispatch(setTotalPayment(price))
//     navigation('/payment')
//   }

//   const handleClick = (id, item) => {
//     if (token) {
//       onCategoryHandle(id, item)
//     } else {
//       setModalShow(true)
//     }
//   }

//   return (
//     <div className="categories_main_container">
//       <div className="categories_topbar">
//         <h2>Membership Plans</h2>
//         <div className="categories_searchbar">
//           <input type="search" placeholder="Find Categories" value={searchCategory} onChange={(e) => setSearchCategory(e.target.value)} />
//           <SearchIcon id="categories_search_icon" />
//         </div>
//       </div>

//       {/******* categories navbar **********/}
//       <div className="categories_navbar">
//         <ul>
//           <li>
//             <a className="active">Plans</a>
//           </li>
//         </ul>
//       </div>

//       {/********* category B  ********/}
//       <div className="categ_by_alphabet">
//         <div className="category_B">
//           <div className="container">

//             <div className="row">
//               {membership?.map((item, index) => {
//                 return <div onClick={() => handleClick(item.id, item)} key={index} className="col-md-4 p-2">
//                   <div className="cat-list" >
//                     <span>{item.title.toLocaleUpperCase()}</span>
//                     <span className="price-tag">{item.price} $ </span>
//                     <span > Expired within <span className="price-tag2" >{item.expire_number}  {item.expire_type}</span> </span>
//                   </div>
//                 </div>
//               })}
//             </div>

//             <div className="toggle">
//               {/* <span onClick={() => setModalShow(true)}>Login or Register</span> */}
//               <Auth isLogin show={modalShow} onHide={() => setModalShow(false)} />
//             </div>

//           </div>

//         </div>
//       </div>


//     </div>
//   );
// }

// export default Membership;


import React, { useState, useEffect } from "react";
import "./membership.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategoryId } from './../redux/questionRedux'
import { setTotalPayment } from "../redux/userRedux";
import Auth from "../auth/Auth";
import axios from 'axios'


function Membership() {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const [modalShow, setModalShow] = useState(false);
  const token = useSelector((state) => state.user.currentUser)?.token
  const [category, setCategory] = useState(null)
  const [searchCategory, setSearchCategory] = useState('')
  const [membership, setMembership] = useState([])

  useEffect(() => {
    axios
      .post("https://oplas.cyberx-infosystem.us/api/membership-plan", {}, {
        headers: {
          "Authorization": token
        }
      })
      .then((response) => {
        setMembership(response.data.data);
      }).catch((err) => console.log(err))
  }, [])

  const onCategoryHandle = (id, price) => {
    dispatch(setCategoryId(id))
    dispatch(setTotalPayment(price))
    navigation('/payment')
  }

  const handleClick = (id, item) => {
    if (token) {
      onCategoryHandle(id, item)
    } else {
      setModalShow(true)
    }
  }

  return (
    <div className="categories_main_container">
      <div className="categories_topbar">
        <h2>Membership Plans</h2>
        <div className="categories_searchbar">
          <input type="search" placeholder="Find Categories" value={searchCategory} onChange={(e) => setSearchCategory(e.target.value)} />
          <SearchIcon id="categories_search_icon" />
        </div>
      </div>

      {/******* categories navbar **********/}
      <div className="categories_navbar">
        <ul>
          <li>
            <a className="active">Plans</a>
          </li>
        </ul>
      </div>

      {/********* category B  ********/}
      <div className="categ_by_alphabet">
        <div className="category_B">
          <div className="container">

            <div className="row">
              {membership?.map((item, index) => {
                return <div onClick={() => handleClick(item.id, item)} key={index} className="col-md-4 p-2">
                  <div className="cat-list" >
                    <span>{item.title.toLocaleUpperCase()}</span>
                    <span className="price-tag">{item.price} TZS </span>
                    <span > Expired within <span className="price-tag2" >{item.expire_number}  {item.expire_type}</span> </span>
                  </div>
                </div>
              })}
            </div>

            <div className="toggle">
              {/* <span onClick={() => setModalShow(true)}>Login or Register</span> */}
              <Auth isLogin show={modalShow} onHide={() => setModalShow(false)} />
            </div>

          </div>

        </div>
      </div>


    </div>
  );
}

export default Membership;

import React,{useEffect} from 'react'
import { useSelector } from 'react-redux'

function PaymentIFrame() {
    const iframeURL = useSelector((state)=>state.user.iframe)
    
      
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <iframe style={{width:'100%', height:'100vh',margin:'20px'}} src={iframeURL}></iframe>
    </div>
  )
}

export default PaymentIFrame

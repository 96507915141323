import React,{useEffect, useState} from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import './notes.css'

const DownloadPaper=()=>{
    const categoryId= useSelector((state)=>state.question.categoryId)
    const token = useSelector((state) => state.user.currentUser)?.token
    const [notes, setNotes] = useState([])
    useEffect(()=>{
      axios.post(`https://oplas.cyberx-infosystem.us/api/pervious-papers`,{
        "levelId":categoryId
      },{
        headers:{
            "Authorization":token
        }
      }).then((res)=>{
        setNotes(res.data.data)
      }).catch((err)=>{
      })  
    },[])
    
  
      
    
      const downloadPdf=(url)=> {
    let file =  getTicketsPdf(url);
    const blob = new Blob([file], {type: 'application/pdf'});
    const link = document.createElement('a');
    link.href = url
    link.download = 'tickets.pdf';
    document.body.appendChild(link);
    link.click();

    setTimeout(function() {
      document.body.removeChild(link);
  }, 100);
  }
    const getTicketsPdf = async (url) => {       
    let data = {};
    let name = 'raul'
    await (async () => {
      const rawResponse = await axios.get(url, name, {
        method: 'get',
        headers: {
          'responseType': 'blob',
          'Content-Type': 'application/json'
        },
      });
    })();

    return data;
  }
  
    return(
        <div className="categories_main_container">
        <div className="categories_topbar">
          <h2>Level</h2>
          <div className="categories_searchbar">
            <input type="search" placeholder="Find Categories" />
            <SearchIcon id="categories_search_icon" />
          </div>
        </div>
  
        {/******* categories navbar **********/}
        <div className="categories_navbar">
          <ul>
            <li>
              <a className="active">Name</a>
            </li>
            <li>
              <a></a>
            </li>
          </ul>
        </div>
  
        {/********* category B  ********/}
        <div className="categ_by_alphabet">
          <div className="category_B">
          
  
              <div className="container">
                <div className="row">
                  {notes?.map((item,index)=>{
                    return <div key={index} className="col-md-2 p-2 " >
                    <div className="cat-list-notes" >
                      <span>{item.title.toLocaleUpperCase()}</span>
                      <button onClick={()=>downloadPdf(item.file)} className="download-btn-note">Download</button>
                          </div>
                      </div>
                  })}
                {notes?null:<h3 style={{padding:'20px'}}>No Paper</h3>}
                </div>
            </div>
  
          </div>
        </div>
  
      </div>
    )
}

export default DownloadPaper
import "./banner.css";
import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../images/oplasslidereser.jpg";

function NoTransitionExample() {
  return (
    <div className="carousel_container">
      <Carousel
        width="100%"
        id="carousel"
        slide={true}
        indicators={true}
        controls={true}
        style={{ marginTop: "1px", paddingRight: "5px", zIndex: "-1" }}
      >
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/first.jpg'
            alt="First slide"
            // width="100%"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/second.jpg'
            alt="Second slide"
            // width="100%"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/third.jpg'
            alt="Third slide"
            // width="100%"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/forth.jpg'
            alt="Third slide"
            // width="100%"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/fifth.jpg'
            alt="Third slide"
            // width="100%"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 banner_image img-fluid "
            src='/slider_images/sixth.jpg'
            alt="Third slide"
            // width="100%"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default NoTransitionExample;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const Reset = () => {
    const navigation = useNavigate()
    const location = useLocation();
    const resetToken = location.pathname.split("/")[2];
    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')

    const onSendReset = () => {
        if (password === '' || cpassword === '') {
            alert('Enter the password')
        } else if (password !== cpassword) {
            alert('New password is not equal to Confirmed password')
        } else {
            axios.post(`https://oplas.cyberx-infosystem.us/api/reset-password`, {
                "new_password": password,
                "confirm_password": cpassword,
                "password_token": resetToken
            }).then((res) => {
                alert('Password Changed Successfully')
                setPassword('')
                setCPassword('')
                navigation('/')
            }).catch((err) => console.log(err))
        }
    }
    return (
        <div className="reset">
            <h1>Reset Password</h1>
            <div className="input_tag">

                <label htmlFor="password" >Create New password</label>
                <input type='password' placeholder="Create New password" value={password} onChange={(e) => setPassword(e.target.value)} required />

                <label htmlFor="password" >Confirmed New password</label>
                <input type='password' placeholder="Confirmed New password" value={cpassword} onChange={(e) => setCPassword(e.target.value)} required />

                <button onClick={onSendReset} >Reset</button>
            </div>
        </div>
    )
}

export default Reset
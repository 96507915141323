import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function SuccessPage() {
    const navigation = useNavigate()
    const [successData, setSuccessData] = useState()
    const user = useSelector((state) => state.user.currentUser)
    const order_tracking_id = useSelector((state) => state.user.order_tracking_id)
    const token = useSelector((state) => state.user.token_pesapal)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
            axios.get(`https://pay.pesapal.com/v3/api/Transactions/GetTransactionStatus?orderTrackingId=${order_tracking_id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                'Authorization': `${token}`
                }
            })
                .then((res) => {
                    setSuccessData(res.data)
                    axios.post('https://oplas.cyberx-infosystem.us/api/orders/update', {
                        "confirmation_code": res.data.confirmation_code,
                        "payment_status_description": res.data.payment_status_description,
                        "status_code": res.data.status_code,
                        "OrderTrackingId": order_tracking_id
                    }, {
                        headers: {
                            "Authorization": user?.token
                        }
                    }).then((res) => {
                        console.log(res.data, 'create api res')
                        if (res.data.status === true) {
                            //   navigation('/payment-iframe')
                        } else {
                            navigation('/failed')
                            alert('Something went wrong')
                        }
                    }).catch((err) => {
                        console.log(err, 'errrrrr')
                    })
    
                }).catch((err) => {
                    console.log(err)
                })
    }, [])
    return (
        <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'20px'}} >
            <img style={{width:'130px', height:'130px'}} src="https://cdn-icons-png.flaticon.com/512/5709/5709755.png" alt="logo" />
            <h2>Transition Completed Successfully</h2>
            <button onClick={()=>navigation('/')} className='btn btn-primary' >Done</button>
        </div>
    )
}

export default SuccessPage

import React,{useState, useEffect} from "react";
import "./level.css";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector , useDispatch} from "react-redux";
import {  useNavigate } from "react-router-dom";
import { setCategoryId } from "../redux/questionRedux";
import axios from 'axios'

function PaperLevel() {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const categoryId= useSelector((state)=>state.question.categoryId)
  const subscriptionTaken= useSelector((state)=>state.user.memberShipRedux)
  const [categoryLevel , setCategoryLevel] = useState([])
  
  useEffect(() => {
    axios.post('https://oplas.cyberx-infosystem.us/api/category-child',{
        "categoryId":categoryId
      })
        .then((res) => {
            setCategoryLevel(res.data.data)
        })
        .catch((err) => {
        })
    }, [])
    
    var date = new Date().toISOString()
    const validate = subscriptionTaken && subscriptionTaken?.map((item) => {
      return item?.expire_date > date ? true : false
    })
    
    const onClickHandle =(id)=>{
      dispatch(setCategoryId(id))
      if(validate?.includes(true)){
        navigation('/download-paper')
      }else if(validate===undefined) {
        navigation('/membership')
      }else {
        navigation('/membership')
      }
      
    }
  
  return (
    <div className="categories_main_container">
      <div className="categories_topbar">
        <h2>Level</h2>
        <div className="categories_searchbar">
          <input type="search" placeholder="Find Categories" />
          <SearchIcon id="categories_search_icon" />
        </div>
      </div>

      {/******* categories navbar **********/}
      <div className="categories_navbar">
        <ul>
          <li>
            <a className="active">Name</a>
          </li>
          <li>
            <a></a>
          </li>
        </ul>
      </div>

      {/********* category B  ********/}
      <div className="categ_by_alphabet">
        <div className="category_B">
        

            <div className="container">
              <div className="row">
                {categoryLevel?.map((item,index)=>{
                  return <div key={index} className="col-md-2 p-2 " onClick={()=>onClickHandle(item.id)} >
                  <div className="cat-list" >
                    <span>{item.name}</span>
                        </div>
                    </div>
                })}
              {categoryLevel?null:<h3 style={{padding:'20px'}}>No Level</h3>}
              </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default PaperLevel;

import "./midSectionCards.css";
import React,{useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { questionGet } from "../redux/answerRedux";
import { FaUserCircle } from "react-icons/fa";
import {setUpdate} from './../redux/userRedux'
import axios from "axios";
import swal from 'sweetalert'


export const MidSectionCards = (props) => {
  const dispatch = useDispatch()
  const navigate= useNavigate()
  const update = useSelector((state)=>state.user.update)
  const token = useSelector((state)=>state.user.currentUser)?.token
  
  const onLCickQuestion =(item)=>{
    axios.post('https://oplas.cyberx-infosystem.us/api/question-view',{
      "questionId":item.id
      }).then((res)=>{
        // console.log(res)
      }).catch((err)=>{
        // console.log(err)
      })
    dispatch(questionGet(item))
    console.log(item,'dfsdf')
    navigate(`/category/question/${item.id}`)
  }
  
  const onVoteHandle=(id)=>{
    if(!token){
      swal("You have to Login first")
    }else {
      axios.post('https://oplas.cyberx-infosystem.us/api/votes',{
      "questionId":id,
      "type":"yes"
      }, {
        headers: {
          "Authorization": token
        }
      }).then((res)=>{
       dispatch(setUpdate(!update))
        swal("Vote Submited")
      }).catch((err)=>{
        // console.log(err)
      })
    }
  }
  
  const onVoteRemoveHandle=(id)=>{
    if(!token){
      swal("You have to Login first")
    }else {
      axios.post('https://oplas.cyberx-infosystem.us/api/votes',{
      "questionId":id,
      "type":"no"
      }, {
        headers: {
          "Authorization": token
        }
      }).then((res)=>{
       dispatch(setUpdate(!update))
        swal("Vote Removed")
      }).catch((err)=>{
        // console.log(err)
      })
    }
  }
  
  return props?.questionData?.questions?.map((item, index) => {
    return (
      <div key={index} className="mid_sec_container">
        <div className="mid_card" >
          <div  onClick={()=>onLCickQuestion(item)} className="mid_card_left">
            <h5>{item.name}</h5>
            <p>{item.name}</p>

            <div className="user">
              <FaUserCircle size="30" style={{ cursor: "pointer" }} />
              <small style={{ cursor: "pointer" }}>{item.authorName}</small>
              <button id="user_btn">Default</button>
              <p>
                Asked on{item.created}
                <span style={{ color: "#5bb54b", cursor: "pointer" }}>
                  {" "}
                  {item.category}
                </span>
              </p>
            </div>
          </div>

          <div className="mid_card_right">
            <div className="question_details">
              <div className="ques_range_main_div">
                <div className="ques_range">
                  <span>{item.views}</span>
                  <p>views</p>
                </div>

                <div className="ques_range">
                  <span>{item.answer}</span>
                  <p>answers</p>
                </div>

                {item.votesAdd==='yes'?
                <div onClick={()=>onVoteRemoveHandle(item.id)} className="ques_range">
                <span style={{backgroundColor:item.votesAdd==='yes'?'#dfdcdc':'white'}}>{item.votes}</span>
                <p>votes</p>
              </div>
              :
              <div onClick={()=>onVoteHandle(item.id)} className="ques_range">
                  <span style={{backgroundColor:item.votesAdd==='yes'?'#dfdcdc':'white'}}>{item.votes}</span>
                  <p>votes</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

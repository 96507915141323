import React from 'react'
import { useNavigate } from 'react-router-dom'

function PaymentFailed() {
    const navigation = useNavigate()
    return (
        
        <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'20px'}} >
            <img style={{width:'130px', height:'130px'}} src="https://icon-library.com/images/failed-icon/failed-icon-7.jpg" alt="logo" />
            <h2>Your Payment Failed</h2>
            <h5>Please try again</h5>
            <button onClick={()=>navigation('/')} className='btn btn-primary' >Done</button>
        </div>
     
    )
}

export default PaymentFailed

import React,{useState, useEffect} from "react";
import "./categories.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {setCategoryId} from './../redux/questionRedux'
import axios from 'axios'


function Paper() {
  const navigation = useNavigate()
  const dispatch= useDispatch()
  const [category , setCategory] = useState(null)
  const [searchCategory, setSearchCategory] = useState('')
  
  useEffect(()=>{
    axios
    .post("https://oplas.cyberx-infosystem.us/api/category",{})
    .then((response) => {
      setCategory(response.data.data);
    }).catch((err)=>console.log(err))
  },[])
  
  const onCategoryHandle =(id)=>{
    dispatch(setCategoryId(id))
    navigation('/paper-level')
  }
  return (
    <div className="categories_main_container">
      <div className="categories_topbar">
        <h2>Categories</h2>
        <div className="categories_searchbar">
          <input type="search" placeholder="Find Categories" value={searchCategory} onChange={(e)=>setSearchCategory(e.target.value)} />
          <SearchIcon id="categories_search_icon" />
        </div>
      </div>

      {/******* categories navbar **********/}
      <div className="categories_navbar">
        <ul>
          <li>
            <a className="active">Name</a>
          </li>
          <li>
            <a></a>
          </li>
        </ul>
      </div>

      {/********* category B  ********/}
      <div className="categ_by_alphabet">
        <div className="category_B">
            <div className="container">
              <div className="row">
                {category?.map((item,index)=>{
                  return <div onClick={()=>onCategoryHandle(item.id)} key={index} className="col-md-2 p-2">
                            <div className="cat-list" >
                              <span>{item.name.toLocaleUpperCase()}</span>
                                  </div>
                              </div>
                          })}
              </div>
          </div>

        </div>
      </div>


    </div>
  );
}

export default Paper;

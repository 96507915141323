import './userprofile.css'
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { loginSuccess, setModalShows } from '../redux/userRedux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
const FormData = require('form-data');

function UserProfile(props) {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const showModal = useSelector((state) => state.user.showModal)
    const [membership, setMembership] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const user = useSelector((state) => state.user.currentUser)
    const token = useSelector((state) => state.user.currentUser)?.token
    const [name, setName] = useState(user.user?.name)
    const [email, setEmail] = useState(user.user?.email)
    const [phone, setPhone] = useState(user.user?.phone)
    const [address, setAddress] = useState(user.user?.address)
    const [update, setUpdate] = useState(false)
    const [profileImg, setProfileImg] = useState(null)

    useEffect(() => {
        axios.post('https://oplas.cyberx-infosystem.us/api/membership-plan', {

        }, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {

            }).catch((err) => console.log(err))

        axios.post('https://oplas.cyberx-infosystem.us/api/fetch-user', {

        }, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                dispatch(loginSuccess(res.data))
            }).catch((err) => console.log(err))


    }, [update])

    useEffect(() => {
        axios.post('https://oplas.cyberx-infosystem.us/api/orders', {

        }, {
            headers: {
                "Authorization": token
            }
        }).then((res) => {
            if(res.data.message === "Not found order"){
                setMembership([])
            }else {
                // console.log(res,'-----------------')
            setMembership(res.data.data)
            }
            
        }).catch((err) => console.log(err, 'errrrrr'))
    }, [])

    const updateProfileHandle = (e) => {
        e.preventDefault()
        axios.post('https://oplas.cyberx-infosystem.us/api/profile', {
            "name": name,
            "phone": phone,
            "address": address
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                setUpdate(!update)
                setModalShow(false)
                if (res.data.data) {
                    swal('Update Successfully ')
                } else {
                    swal('Something Wrong')
                }
            }).catch((err) => {
                swal('Something Wrong')
            })
    }

    const onProfileChange = (e) => {
        setProfileImg(e.target.files[0])
        let bodyFormData = new FormData();
        let imagedata = document.querySelector('input[type="file"]').files[0];
        bodyFormData.append("file", imagedata);
        
        console.log(bodyFormData,'bodyFormDatabodyFormData')

        axios({
            method: "post",
            url: "https://oplas.cyberx-infosystem.us/api/update-profile-image",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data", "Authorization": token },
        })
            .then((res) => {
                setUpdate(!update)
                setModalShow(false)
                if (res.data.data) {
                    swal('Update Successfully ')
                } else {
                    swal('Something Wrong')
                }
            }).catch((err) => {
                swal('Something Wrong')
            })
    }
    
    return (
        <>
            <Modal
                {...props}
                size="md"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id="modal_header" closeButton>
                    <Modal.Title id="modal_title">
                        Edit Profile
                    </Modal.Title>
                </Modal.Header>
                <form action="" method="post" id="fileuploadForm">
                    <div className='modal_first_profile'>
                        {user.user?.profile ? <img className='profile_img' src={`${user.user?.profile}`} alt="img" />
                            :
                            <img className='profile_img' src='https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360' alt="img" />
                        }
                        <input type="file" name="image" accept="image/png, image/jpeg ,image/jpg" id="imageValue" onChange={onProfileChange} />
                        {/* <p>Upload New Avatar</p> */}
                    </div>

                    <div className='modal_first_profile1'>
                        <label htmlFor="text">Email</label>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} disabled />

                        <label htmlFor="text">First Name</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

                        <label htmlFor="text">Address</label>
                        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />

                        <label htmlFor="text">Mobile No</label>
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <button className='update_profile_btn' onClick={updateProfileHandle} >UPDATE PROFILE</button>
                </form>


                {/* <CKEditor  /> */}
                <Modal.Body>
                </Modal.Body>
            </Modal>

            <div className="carousel_container">
                <div className="heading_profile">
                    <h4>User's Profile</h4>
                    <p className='edit_profile' onClick={() => setModalShow(true)} >Edit</p>
                </div>

                <div className='container-fluid border ' >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="first ">
                                <div className="row">

                                    <div className="col-md-5">
                                        {user.user?.profile ? <img className='profile_img' src={`${user.user?.profile}`} alt="img" />
                                            :
                                            <img className='profile_img' src='https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360' alt="img" />
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="column details_user">
                                            <h5>User</h5>
                                            <p>{user.user?.name}</p>
                                            <p>{user.user?.email}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="second">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="column details_user2">
                                            <h5 className='w-100'>Mobile No :-  <span style={{ color: '#3498db' }}>{user.user?.phone}</span></h5>
                                            <p>Address :- </p>
                                            <span style={{ color: '#3498db' }}>{user.user?.address}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="third p-3">
                                <h4>Your Active Package</h4>
                                <div className="row">

                                    <div className="col-md-12">
                                             <div className="column details_user3">
                                                <p className='w-100'>Plan :-  <span style={{ color: '#3498db' }}>{membership[0]?.plan}</span></p>
                                                <p className='w-100'>Expire Date :-  <span style={{ color: '#3498db' }}>{membership[0]?.expire_date}</span></p>
                                                {membership.length>1? <p onClick={()=>navigation('/your-plan')} className='w-100 show_more'>Show More</p>:null }
                                                
                                            </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserProfile;

import React from "react";
import UserProfile from "./UserProfile";
import AllQuestions from "../components/AllQuestions";
// import { RightSideBar } from "./../components/RightSideBar";

const Profile = () => {
  return (
    <div>
      {/* <RightSideBar /> */}
      <UserProfile />
      <AllQuestions />
    </div>
  );
};

export default Profile;

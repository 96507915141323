import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import axios from "axios";
import AllQuestions from "../components/AllQuestions";
import AllServices from "./AllServices";
import { setMemberShipRedux } from "../redux/userRedux";
import { useSelector, useDispatch } from "react-redux";
// import { RightSideBar } from "./../components/RightSideBar";
import { Helmet } from "react-helmet";

const Home = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.user.currentUser)?.token

useEffect(()=>{
  axios.post('https://oplas.cyberx-infosystem.us/api/orders', {
    
  }, {
      headers: {
          "Authorization": token
      }
  }).then((res)=>{
      dispatch(setMemberShipRedux(res.data.data))
  }).catch((err)=>console.log(err,'errrjhfuyfi'))  
},[])

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  return (
    <div>
       <Helmet>
       <title>Oplas - Your Study Guide for  All Subjects</title>
      <meta name="description" content="Elevate your Necta exam preparation with Oplas extensive collection of exam notes covering all subjects. Our meticulously crafted study materials are designed to help you master the curriculum."/>
      <link rel="canonical" href="https://oplas.co.tz/" />
      <meta name="robots" content="index, follow, imageindex"/>
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Oplas - Your Study Guide for All Subjects"/>  
      <meta property="og:description" content="Elevate your Necta exam preparation with Oplas extensive collection of exam notes covering all subjects. Our meticulously crafted study materials are designed to help you master the curriculum."/>
      <meta property="og:site_name" content="Oplas"/>
      </Helmet>
      {/* <RightSideBar /> */}
      <Banner />
      <AllServices/>
      <AllQuestions />
    </div>
  );
};

export default Home;

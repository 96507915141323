import React,{useEffect, useState} from "react";
import "./allAnswers.css";
import {
  BiChevronUpCircle,
  BiChevronDownCircle,
  BiUpArrowAlt,
} from "react-icons/bi";
import {
  AiOutlineCheckCircle,
} from "react-icons/ai";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { FaUserCircle, FaComment } from "react-icons/fa";
import { IoMdShareAlt } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { setUpdate } from "../redux/userRedux";
import axios from "axios";
import Auth from "../auth/Auth";
import { Update } from "@mui/icons-material";

function AllAnswers() {
  const navigation =useNavigate()
  const dispatch=useDispatch()
  const [answer, setAnswer] = useState()
  const [updated, setUpdated] = useState(false)
  const [modalShow, setModalShow]= useState(false)
  const [postAnswer, setPostAnswer] = useState('')
  const question = useSelector((state)=>state.answer.questionData)
  const token = useSelector((state)=>state.user.currentUser)?.token
  const updates = useSelector((state)=>state.user.update)
  useEffect(() => {
    axios
      .post(
        "https://oplas.cyberx-infosystem.us/api/question-info",
        {
          "questionId": question.id,
        },)
      .then((response) => {
        setAnswer(response.data.data);
      }).catch((err)=>{
        // console.log(err)
      })
  }, [updates,updated]);
  
  const onPostAnswer = (e) => {
    e.preventDefault()
    if (token) {
        if(postAnswer.length<=10){
          swal('Your Words Should be 10 Letters or Above')
        } else {
          axios.post('https://oplas.cyberx-infosystem.us/api/answer-post', {
            "questionId": question.id,
            "description": postAnswer
          }, {
            headers: {
              "Authorization": token
            }
          })
            .then((res) => {
              swal('Answer posted')
              setPostAnswer('')
              dispatch(setUpdate(!Update))
              setUpdated(!updated)
              // setPostAnswer('')
              // setVisible(false);
            })
            .catch((err) => {
            })
        }
    } else {
      setModalShow(true)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="all_answers_main_container">
      <div className="all_answers_topbar">
        <span>&laquo; Home</span>
        <h1>
          {answer?.question?.name}
        </h1>
      </div>
      <hr />

      {/******* question display section  ********/}
      <Auth isLogin show={modalShow} onHide={() => setModalShow(false)} />
      

      <div className="ques_display_section">
        <div className="ques_display_left_section">
          <ul>
            <li>
              <BiChevronUpCircle size="25" color="#c8c9ca" />
            </li>
            <li>
              <small
                style={{
                  borderRadius: "3px",
                  color: "#fff",
                  padding: "5px 20px 5px 20px",
                  backgroundColor: "#e65c23",
                }}
              >
                0
              </small>
            </li>
            <li>
              <BiChevronDownCircle size="25" color="#c8c9ca" />
            </li>
            <li>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "none",
                  backgroundColor: "#e65c23",
                  color: "#fff",
                  padding: "0 10px 0 10px",
                }}
              >
                <BiUpArrowAlt />
                Pump
              </button>
            </li>
          </ul>
        </div>

        <div className="ques_display_right_section">

          <div className="ques_desc">
            <p>
            {answer?.question?.name}
            </p>
            <hr />
          </div>

          <div className="ques_asked_by">
            <div className="ques_asked_by_left">
              <FaUserCircle size="30" style={{ cursor: "pointer" }} />
              <small style={{ paddingRight: "10px" }}>{answer?.question?.authorName}</small>
              <small
                style={{
                  backgroundColor: "#9e2800",
                  padding: "0 10px 0 10px",
                  color: "#fff",
                  borderRadius: "3px",
                }}
              >
                premier
              </small>
              <small>Asked on {answer?.question?.created}</small>
            </div>

            <div className="ques_asked_by_right">
              <small>Share</small>
              <IoMdShareAlt style={{ paddingLeft: "5px" }} size="25" />
              <small>
                Comments(0)
                <FaComment style={{ paddingLeft: "5px" }} size="20" />
              </small>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="all_answers_nav">
        <div className="all_answers_left_nav">
          <big>Answers {answer?.question?.answer}</big>
        </div>
        <div className="all_answers_rigth_nav">
          <p>Votes({answer?.question?.votes})</p>
          <p>oldest</p>
        </div>
      </div>

      {answer?.question?.answersList?.map((item,index)=>{
        return <div style={{display:'flex' , border:'0.5px solid #dddfe1'}}>
          <div className="answer_explained_header">
        <div className="answer_display_left_section">
          <ul>
            <li>
              <BiChevronUpCircle size="25" color="#c8c9ca" />
            </li>
            <li>
              <small
                style={{
                  borderRadius: "3px",
                  color: "#fff",
                  padding: "5px 20px 5px 20px",
                  backgroundColor: "#e65c23",
                }}
              >
                0
              </small>
            </li>
            <li>
              <BiChevronDownCircle size="25" color="#c8c9ca" />
            </li>
            <li>
              <AiOutlineCheckCircle size="25" color="#c8c9ca" />
            </li>
          </ul>
        </div>
      </div>

      <div className="answer_box" >
      <div className="answer_describe">
     <p> {item.description}</p>
      </div>

      <div className="ques_asked_by">
        <div className="ques_asked_by_left">
          <FaUserCircle size="30" style={{ cursor: "pointer" }} />
          <small>{item.authorName}</small>
          <small className="default_tag" >Default</small>
          <small>Asked on {item.created}</small>
        </div>
        <div className="ques_asked_by_right">
          <small>Share</small>
          <IoMdShareAlt style={{ paddingLeft: "5px" }} size="25" />
          <small>
            Comments({answer?.question?.answersList?.length})
            <FaComment style={{ paddingLeft: "5px" }} size="25" />
          </small>
        </div>
      </div>
      </div>
        </div>
      })}
      <hr />

      <div className="post_ques_solution">
        <h5 className="post_ques_solution_title">Your Answer</h5>
        <form onSubmit={onPostAnswer}>
          <textarea
            name="post_solution"
            id="post_solution"
            cols="100"
            rows="15"
            style={{ border: "1px solid #c8c9ca;" }}
            value={postAnswer}
            onChange={(e)=>setPostAnswer(e.target.value)}
          ></textarea>
          <br />
          <button className="postanswer_btn" type='submit'>POST ANSWER</button>
        </form>
        <p>
          By posting your answer, you agree to the{" "}
          <span className="pointer" onClick={()=> navigation('/privacy')} style={{ color: "#76b264", cursor:'pointer' }}>privacy policy</span> and{" "}
          <span className="pointer" onClick={()=> navigation('/term')} style={{ color: "#76b264", cursor:'pointer' }}>terms of service.</span>
        </p>
      </div>
    </div>
  );
}

export default AllAnswers;
